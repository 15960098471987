import React, { useEffect } from 'react';
import { useLocation } from "react-router-dom";


export const Legal = ({ t }) => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <div className='cookies'>

            <h2 className='cookies__heading'>{t('legal.title.1')}</h2>

            <div className='cookies__info'>
                <ul>
                    <li>
                        {t('legal.1')}
                    </li>

                    <li>{t('legal.2')} <b>CARTERA DE INVERSIONES CM, S.A.</b></li>
                    <li>{t('legal.3')}Passeig de la Bonanova 64, 3, 08017 Barcelona, (Barcelona) </li>
                    <li>NIF: A58774084 </li>
                    <li>{t('legal.4')} 934876666 </li>
                    <li>E-Mail: carteracm@carteracm.com</li>
                    <li>{t('legal.5')} https://carteracm.com </li>
                    <li>{t('legal.6')}</li>
                </ul>

                <h2 className='cookies__heading--secondary'>{t('legal.title.9')}</h2>
                <p className='cookies__p'>
                    <b>CARTERA DE INVERSIONES CM, S.A.</b>
                    {t('legal.9')}
                </p>
                <p className='cookies__p'>
                    {t('legal.10')} <b>CARTERA DE INVERSIONES CM, S.A.</b>
                </p>

                <p className='cookies__p'>
                    {t('legal.11')}
                </p>
                <p className='cookies__p'>
                    {t('legal.12')}
                </p>

                <h2 className='cookies__heading--secondary'> {t('legal.title.13')}</h2>
                <h3 className='cookies__heading--tertiary'> {t('legal.title2.13')}</h3>
                <p className='cookies__p'>
                    {t('legal.13')}
                </p>

                <h3 className='cookies__heading--tertiary'>{t('legal.title.14')}</h3>

                <p className='cookies__p'>
                    {t('legal.14.1')}
                    <b>CARTERA DE INVERSIONES CM, S.A.</b>
                </p>
                <h3 className='cookies__heading--tertiary'>{t('legal.title.15')}</h3>
                <p className='cookies__p'>
                    {t('legal.15.1')}<b>CARTERA DE INVERSIONES CM, S.A.</b> {t('legal.15.2')}
                </p>

                <h3 className='cookies__heading--tertiary'> {t('legal.title.16')}</h3>

                <p className='cookies__p'>
                    {t('legal.16')}
                </p>

                <h3 className='cookies__heading--tertiary'> {t('legal.title.17')}</h3>

                <p className='cookies__p'>
                    {t('legal.17')}
                </p>

                <p className='cookies__p'>
                    {t('legal.18')}
                </p>

                <p className='cookies__p'>
                    {t('legal.19')}
                </p>

                <p className='cookies__p'>
                    {t('legal.20')}

                </p>

                <p className='cookies__p'>
                    {t('legal.21')}
                </p>

                <p className='cookies__p'>
                    {t('legal.22')}
                </p>

                <p className='cookies__p'>
                    {t('legal.23')}
                </p>

                <p className='cookies__p'>
                    {t('legal.24')}
                </p>


                <h3 className='cookies__heading--tertiary'>{t('legal.title.25')}</h3>

                <p className='cookies__p'>
                    {t('legal.25')}
                </p>

                <p className='cookies__p'>
                    {t('legal.26')}
                </p>

                <p className='cookies__p'>
                    <b>CARTERA DE INVERSIONES CM, S.A.</b> {t('legal.27')}
                </p>

                <p className='cookies__p'>
                    {t('legal.28')}

                </p>


                <h3 className='cookies__heading--tertiary'>4. COOKIES</h3>

                <p className='cookies__p'>
                    {t('legal.29')}
                </p>

                <h3 className='cookies__heading--tertiary'>5.LINKS </h3>

                <p className='cookies__p'>
                    {t('legal.31')}
                </p>

                <p className='cookies__p'>
                    {t('legal.32')}
                    <b>CARTERA DE INVERSIONES CM, S.A.</b>
                </p>

                <p className='cookies__p'>
                    {t('legal.33')}
                </p>


                <p className='cookies__p'>
                    <b>CARTERA DE INVERSIONES CM, S.A.</b> {t('legal.34.1')} <b>CARTERA DE INVERSIONES CM, S.A.</b> {t('legal.34.2')}
                </p>

                <h3 className='cookies__heading--tertiary'> {t('legal.title.35')}</h3>

                <p className='cookies__p'>
                    {t('legal.35')}
                </p>

                <h3 className='cookies__heading--tertiary'> {t('legal.title.36')}</h3>

                <p className='cookies__p'>
                    {t('legal.36')}
                </p>

                <p className='cookies__p'>
                    {t('legal.37')}

                </p>

                <p className='cookies__p'>
                    {t('legal.38')}
                </p>

                <p className='cookies__p'>
                    {t('legal.39')}
                </p>

                <p className='cookies__p'>
                    {t('legal.40')}
                </p>

                <h3 className='cookies__heading--tertiary'>{t('legal.title.41')}</h3>

                <p className='cookies__p'>
                    {t('legal.41')}
                </p>

            </div>
        </div>
    )
}
