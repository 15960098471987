import React, { useState, useRef } from 'react';
import { CarouselParticipadas } from './CarouselParticipadas';
import { useTranslation } from 'react-i18next';
import { useIsVisible } from 'react-is-visible'

export const MenuParticipadas = () => {
    const nodeRef = useRef();
    const isVisible = useIsVisible(nodeRef);
    const [category, setCategory] = useState('');
    const [activeDiv, setActiveDiv] = useState(null);
    const [showPortfolioStartups, setShowPortfolioStartups] = useState(false);
    const [showDesinversionesStartups, setShowDesinversionesStartups] = useState(false)
    const [btnPortFolioActive, setBtnPortfolioactive] = useState(false);
    const [btnDesActive, setBtnDesActive] = useState(false);
    const [t] = useTranslation();

    const isMobile = window.innerWidth <= 768

    function filterByCategory(e) {

        if(!e.target.textContent.length > 30) return;
        
        if (e.target.textContent === t('participadas-startups')) {
            setCategory('Startups')
        }
        if (e.target.textContent === t('participadas-financieras')) {
            setCategory('Financieras')
        }
        if (e.target.textContent === t('participadas-inmobiliarias')) {
            setCategory('Inmobiliarias')
        } 
        if (e.target.textContent === t('participadas-industriales')) {
            setCategory('Industriales')
        } 
       
    }

    function setActiveDivHandler(e) {
        setActiveDiv(e.target.textContent);
        setShowDesinversionesStartups(false);
        setShowPortfolioStartups(false);
        setBtnDesActive(false)
        setBtnPortfolioactive(false)
    }

    return (
        <>
            <div className='participadas__menu' onClick={(e) => filterByCategory(e)} ref={ nodeRef }>
                <div onClick={(e) => setActiveDivHandler(e)} className={activeDiv === t('participadas-industriales') ? 'participadas__div participadas__div--active ' : 'participadas__div'} style={{ backgroundImage: "url('/assets/participadas_cemento.webp')" }}>
                    <h2 className="participadas__menu-title">{t('participadas-industriales')}</h2>
                </div>
                    { isMobile && activeDiv === t('participadas-industriales') && <CarouselParticipadas isVisible={ isVisible } category={ category } hooks={ [setShowPortfolioStartups, setShowDesinversionesStartups, showDesinversionesStartups, showPortfolioStartups, setBtnDesActive, setBtnPortfolioactive, btnDesActive, btnPortFolioActive ]} />}
                <div onClick={(e) => setActiveDivHandler(e)} className={activeDiv === t('participadas-startups') ? 'participadas__div participadas__div--active ' : 'participadas__div'} style={{ backgroundImage: "url('/assets/participadas_startup.webp')" }}>
                    <h2 className="participadas__menu-title">{t('participadas-startups')}</h2>
                </div>
                { isMobile && activeDiv === t('participadas-startups') && <CarouselParticipadas isVisible={ isVisible } category={ category } hooks={ [setShowPortfolioStartups, setShowDesinversionesStartups, showDesinversionesStartups, showPortfolioStartups, setBtnDesActive, setBtnPortfolioactive, btnDesActive, btnPortFolioActive ]} />}

                <div onClick={(e) => setActiveDivHandler(e)} className={activeDiv === t('participadas-financieras') ? 'participadas__div participadas__div--active ' : 'participadas__div'} style={{ backgroundImage: "url('/assets/participadas_financieras.webp')" }}>
                    <h2 className="participadas__menu-title">{t('participadas-financieras')}</h2>
                </div>
                { isMobile && activeDiv === t('participadas-financieras') && <CarouselParticipadas isVisible={ isVisible } category={ category } hooks={ [setShowPortfolioStartups, setShowDesinversionesStartups, showDesinversionesStartups, showPortfolioStartups, setBtnDesActive, setBtnPortfolioactive, btnDesActive, btnPortFolioActive ]} />}

                <div onClick={(e) => setActiveDivHandler(e)} className={activeDiv === t('participadas-inmobiliarias') ? 'participadas__div participadas__div--active ' : 'participadas__div'} style={{ backgroundImage: "url('/assets/participadas_inmobiliarias.webp')" }}>
                    <h2 className="participadas__menu-title">{t('participadas-inmobiliarias')}</h2>
                </div>
                {   isMobile && activeDiv === t('participadas-inmobiliarias')&& <CarouselParticipadas isVisible={ isVisible } category={ category } hooks={ [setShowPortfolioStartups, setShowDesinversionesStartups, showDesinversionesStartups, showPortfolioStartups, setBtnDesActive, setBtnPortfolioactive, btnDesActive, btnPortFolioActive ]} />}

            </div>
            { !isMobile && <CarouselParticipadas isVisible={ isVisible } category={ category } hooks={ [setShowPortfolioStartups, setShowDesinversionesStartups, showDesinversionesStartups, showPortfolioStartups, setBtnDesActive, setBtnPortfolioactive, btnDesActive, btnPortFolioActive ]} /> }
        </>
    )
}
