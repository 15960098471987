import React from 'react';
import { CarouselHome } from './CarouselHome';

export const Home = ({ t, showNavbar}) => {

    return (
        <div>
            <CarouselHome t={t} />
        </div>
    )
}
