import React from 'react';
import { CarouselFotos } from './CarouselFotos';
import { CarouselMisionVision } from './CarouselMisionVision';
import { Trencadis } from './Trencadis';
import { ValoresCorporativos } from './ValoresCorporativos';

export const QuienesSomos = ({ t }) => {

    return (
        <div className='who'>
            <h1 className='who__heading' id='nosotros'>{t('whoAreWe')}</h1>
            <h3 className='who__heading'>CARTERA DE INVERSIONES <b>C.M.</b> S.A, FAMILY OFFICE</h3>
            <div className='explanation--container who__explanation'>
                <p className='blue--intro who__intro--blue'>{t('companyInfo-2')}</p>
                <p className='regular--intro who__intro--regular'> {t('companyInfo-3')}</p>
            </div>
            <Trencadis className="qs__trencadis who__grid"/>
            <CarouselFotos/>
            <CarouselMisionVision t={t}/>
            <ValoresCorporativos t={t} />
        </div>
    )
}

