import React from 'react'

export const Responsabilidad = ({ t }) => {
    return (
        <div className='what__resp'>
            <h1 className='what__resp-heading' id='resp-social-corp'>{t('corpResp2')}</h1>
            <article className='what__resp-article'>
            CARTERA DE INVERSIONES <b>CM, S.A.</b> {t('companyInfo-18')} <a className='what__link' href='http://www.joaquimmolinsfigueras.org/'>{t('link-fund')}</a>
            </article>
            <img className='org-logo what__company' src='/assets/org_logo.webp' alt='org-logo' />
        </div>
    )
}
