import {axiosInstance} from './api';

export const createCompany = (data) => {
    return axiosInstance.post('/', data)
}

export const editCompany = (companyId, data) => {
    return axiosInstance.put(`/${companyId}`, data);
}

export const deleteCompany = (companyId, data) => {
    return axiosInstance.delete(`/${companyId}`, data)
}

export const getCompanies = () => {
    return axiosInstance.get('/companies');
}

export const getCompanyData = (companyId) => {
    return axiosInstance.get(`/${companyId}`);
}