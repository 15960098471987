import React, { useState, useRef, useEffect } from 'react';
import { FaSortDown, FaBars } from 'react-icons/fa';
import { RiCloseFill } from 'react-icons/ri';
import { Link } from "react-scroll";

export const Navbar = ({ handlers, t }) => {
    const [changeLanguageHandler] = handlers;
    const [showLanguage, setShowLanguages] = useState(false)
    const [showDropDownQH, setShowDropDownQH] = useState(false);
    const [showDropDownQS, setShowDropDownQS] = useState(false);
    const [active, setActive] = useState('');
    const ref = useRef();
    const [menuIsVisible, setMenuIsVisible] = useState(false);


    const dropdownHandlerQH = (e) => {
        setShowDropDownQH(!showDropDownQH);
        setShowDropDownQS(false);
        setActive(e.target.textContent);
    }

    const dropdownHandlerQS = (e) => {
        setShowDropDownQS(!showDropDownQS);
        setShowDropDownQH(false);
        setActive(e.target.textContent);

    }
    const handleClick = e => {
        if (ref.current && ref.current.contains(e.target)) return;
        setShowDropDownQS(false)
        setShowDropDownQH(false)
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClick);
    });

    const hideDropDownHandler = () => {
        setShowDropDownQH(false);
        setShowDropDownQS(false)
    }

    const showMenu = () => setMenuIsVisible(!menuIsVisible)

    const activeLanguage = localStorage.getItem('i18nextLng');

    return (
        <>
            <span><a className="navbar__logo navbar__logo--responsive" href="/#top">CARTERA DE <br /> INVERSIONES </a> <span className='navbar__cm-bold navbar__logo--responsive'>C.M. </span></span>

            { !menuIsVisible ?  <FaBars onClick={showMenu} className="navbar__menu--open" /> : <RiCloseFill onClick={showMenu} class="navbar__menu--close" /> }

            <nav className={menuIsVisible ? 'navbar--responsive navbar__responsive' : 'navbar'} ref={ref}>
                {/* <a href="/#top"><img className='cartera' src='/logo_cartera2.jpg' alt='logo-navbar' /></a> */}
                <div className="navbar__container">
                    <button className='button' onClick={(e) => dropdownHandlerQS(e)}>{t('whoAreWe')} <FaSortDown /></button>
                    {
                        active.includes(t('whoAreWe')) && showDropDownQS &&
                        <div className='navbar__dropdown' >
                            <div className='navbar__items'>
                                <Link to='nosotros'
                                    onClick={hideDropDownHandler}
                                    spy={true}
                                    hashSpy={true}
                                    smooth={true}
                                    offset={-70}
                                    duration={100}> <span className='navbar-items navbar__links'>{t('aboutUs')}</span></Link>
                                <Link to='mision_y_vision'
                                    onClick={hideDropDownHandler}
                                    spy={true}
                                    hashSpy={true}
                                    smooth={true}
                                    offset={-70}
                                    duration={100}><span className='navbar-items navbar__links'>{t('companyInfo-5')}</span></Link>
                                <Link to='valores_corporativos'
                                    onClick={hideDropDownHandler}
                                    spy={true}
                                    hashSpy={true}
                                    smooth={true}
                                    offset={-70}
                                    duration={100}><span className='navbar-items navbar__links'>{t('corpValues')}</span></Link>
                            </div>
                        </div>
                    }
                </div>
                <div className="navbar__container">
                    <button className='button' onClick={(e) => dropdownHandlerQH(e)} >{t('whatWeDo')} <FaSortDown /></button>
                    <div className='navbar__dropdown' onClick={hideDropDownHandler}>
                        {
                            active.includes(t('whatWeDo')) && showDropDownQH &&
                            <div className='navbar__items'>
                                <Link to='que-hacemos'
                                    onClick={hideDropDownHandler}
                                    spy={true}
                                    hashSpy={true}
                                    smooth={true}
                                    offset={-70}
                                    duration={100}><span className='navbar__links'>{t('whatWeDo')}</span></Link>


                                <Link to='organos-de-gobierno'
                                    onClick={hideDropDownHandler}
                                    spy={true}
                                    hashSpy={true}
                                    smooth={true}
                                    offset={-70}
                                    duration={100}><span className='navbar__links'>{t('orgGov')}</span></Link>
                                <Link to='resp-social-corp'
                                    onClick={hideDropDownHandler}
                                    spy={true}
                                    smooth={true}
                                    offset={-70}
                                    duration={100}><span className='navbar__links'>{t('corpResp')}</span></Link>
                            </div>
                        }
                    </div>
                </div>
                <span><a className='navbar__logo navbar__logo--desktop' href="/#top">CARTERA DE INVERSIONES </a> <span className='navbar__cm-bold navbar__logo--desktop'>C.M. </span></span>
                <Link to='participadas'
                    className="navbar__link"
                    onClick={hideDropDownHandler}
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={100}><span className='navbar__categories'>{t('participadas-title')}</span></Link>

                <Link to='contacto'
                    className="navbar__link"
                    onClick={hideDropDownHandler}
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={100}><span className='navbar__categories'>{t('contact')}</span></Link>

                <div className='navbar__language' onClick={() => setShowLanguages(!showLanguage)}>
                    <span className={activeLanguage === 'es' ? 'navbar__language__item navbar__language__item--active' : 'navbar__language__item'} onClick={() => changeLanguageHandler('es')}>ESP</span>
                    <span className={activeLanguage === 'cat' ? 'navbar__language__item navbar__language__item--active' : 'navbar__language__item'} onClick={() => changeLanguageHandler('cat')}>CAT</span>
                    <span className={activeLanguage === 'en' ? 'navbar__language__item navbar__language__item--active' : 'navbar__language__item'} onClick={() => changeLanguageHandler('en')}>ENG</span>
                </div>
            </nav>
        </>
    )
}
