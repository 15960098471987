import React from 'react'

export const Intro = ({ t }) => {
    return (
        <div className='what__intro'>
            <img className='what__img' src='/assets/terraza.webp' alt='terraza' />
            <div className="what__grid">
                <p className='what__description'>{t('whatWeDo-1')}</p>
            </div>

            <ol className='qh-list what__list'>
                <li>{t('whatWeDoFin')} <br />{t('whatWeDoFin2')}</li>
                <li>{t('whatWeDoStart')}</li>
                <li>{t('whatWeDoComp')}</li>
                <li>{t('whatWeDoState')}</li>
            </ol>

        </div>
    )
}
