import React from 'react'

export const OrganosDeGobierno = ({ t }) => {
    return (
        <div className='what__gov'>
            <div className='what__wrapper' id='organos-de-gobierno'>
            </div>
            <h1 className='what__gov-heading' >{t('whatWeDo.title')}</h1>
            <div className='what__org'>
                <article>
                    <img className='what__logo' src='/assets/org_admin.webp' alt='admin-pic' />
                    <h5 className='what__article'>{t('whatWeDo-3-Bold')}</h5>
                    <p className='what__info'>{t('whatWeDo-3')} </p>
                </article>
                <article>
                    <img className='what__logo' src='/assets/org_dir.webp' alt='admin-pic' />
                    <h5 className='what__article'>{t('whatWeDo-4-Bold')}</h5>
                    <p className='what__info'>{t('whatWeDo-4')} </p>
                </article>
                <article>
                    <img className='what__logo' src='/assets/org_lupa.webp' alt='admin-pic' />
                    <h5 className='what__article'>{t('whatWeDo-5-Bold')}</h5>
                    <p className='what__info'>{t('whatWeDo-5')} </p>
                </article>
            </div>
            <div className='what__stairs' style={{ background: "url('/assets/escalera_2.jpg')" }}></div>
        </div>
    )
}
