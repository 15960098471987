import React, { useEffect } from 'react'
import { useLocation } from "react-router-dom";


export const PrivacyPolicy = ({ t }) => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);


    return (
        <div className='cookies'>
            <h2 className='cookies__heading'>{t('privacy.title.1')}</h2>
            <div className='cookies__info'>
                <h2 className='cookies__heading--tertiary'>{t('privacy.title.1.2')}</h2>

                <p className='cookies__p'>
                    {t('privacy.1')}
                </p>

                <p className='cookies__p'>
                    {t('privacy.2')}
                </p>

                <p className='cookies__p'>

                    {t('privacy.3')}
                </p>

                <h2 className='cookies__heading--tertiary'> {t('privacy.title.4')}</h2>

                <p className='cookies__p'>
                    <b>CARTERA DE INVERSIONES CM, S.A.</b>  {t('privacy.8')}
                </p>    
        
                <h2 className='cookies__heading--tertiary'>  {t('privacy.title.17')}</h2>

                <p className='cookies__p'>
             {t('privacy.17.1')}
                </p>

                <ol>
                    <li>  {t('privacy.17')}</li>
                    <li>  {t('privacy.18')}</li>
                    <li>  {t('privacy.19')}</li>
                </ol>

                <p className='cookies__p'>
                    {t('privacy.29')}
                </p>

                <p className='cookies__p'>
                    {t('privacy.30')}
           
                </p>

                <p className='cookies__p'>
                    {t('privacy.31')}
                
                </p>

                <p className='cookies__p'>
                    {t('privacy.32')}
                    
                </p>

                <h2 className='cookies__heading--tertiary'> {t('privacy.title.33')}</h2>

                <p className='cookies__p'>
                    {t('privacy.33')}
                 
                </p>

                <p className='cookies__p'>
                    {t('privacy.34')}

                </p>

                <p className='cookies__p'>
                    {t('privacy.35')}

                </p>

                <p className='cookies__p'>
                    {t('privacy.36')}

                </p>

                <h2 className='cookies__heading--tertiary'>{t('privacy.title.37')}</h2>

                <p className='cookies__p'>
                    {t('privacy.37')}

                </p>

                <h2 className='cookies__heading--tertiary'> {t('privacy.title.38')}</h2>

                <p className='cookies__p'>

                    {t('privacy.38.1')} {t('privacy.38.2')}
                </p>

                <h2 className='cookies__heading--tertiary'> {t('privacy.title.39')}</h2>
                <p className='cookies__p'>
                    {t('privacy.39')}

                </p>

                <p className='cookies__p'>
                    {t('privacy.40')}
                </p>

                <ul>
                    <li> {t('privacy.41')} </li>
                    <li> {t('privacy.42')}</li>
                    <li> {t('privacy.43')} </li>
                    <li> {t('privacy.44')}</li>
                    <li> {t('privacy.45')}</li>
                    <li> {t('privacy.46')} </li>
                    <li> {t('privacy.47')} </li>
                </ul>


                <p className='cookies__p'>
                    {t('privacy.48')}
                </p>

                <p className='cookies__p'>
                    {t('privacy.49')}
                </p>

                <h2 className='cookies__heading--tertiary'>{t('privacy.title.50')}</h2>
                <p className='cookies__p'>
                    {t('privacy.50')}
                </p>
                <h2 className='cookies__heading--tertiary'> {t('privacy.title.51')}</h2>
                <p className='cookies__p'>
                    {t('privacy.51')}
                </p>
                <h2 className='cookies__heading--tertiary'> {t('privacy.title.69')}</h2>
                <p className='cookies__p'>
                    {t('privacy.69')}
                    <b>CARTERA DE INVERSIONES CM, S.A.</b>
                </p>
                <h2 className='cookies__heading--tertiary'>{t('privacy.title.70')}</h2>
                <p className='cookies__p'>
                    {t('privacy.70')}
   
                </p>
                <h2 className='cookies__heading--tertiary'>{t('privacy.title.71')}</h2>
                <p className='cookies__p'>
                    {t('privacy.71')}
                </p>

            </div>
        </div>
    )
}
