import React from 'react'
import { useTranslation } from 'react-i18next';

export const ParticipadaFicha = ({ companyInfo, isOpen, modalHandler }) => {

    const { englishDescription, spanishDescription, catalanDescription } = companyInfo.description;
    const [ t, i18n ] = useTranslation();

    return (
        companyInfo && isOpen ?
            <div className='participadas__ficha'>
                <span className='participadas__cross' onClick={() => modalHandler()}>&times;</span>
                <img className='participadas__img' src={companyInfo && companyInfo.companyLogo} alt='companyLogo' />
                <hr className='participadas__hr' />
                <h2 className='participadas__year'> {companyInfo && companyInfo.year && `${t('year')} ${companyInfo.year}`}</h2>
                <h2 className='participadas__year'>{companyInfo && companyInfo.stopInvestingYear && `${t('common.label.disinvestmentYear')} ${companyInfo.stopInvestingYear}`}</h2>
                <p className='participadas__description'>{companyInfo && i18n.language === 'en' ? englishDescription : companyInfo && i18n.language === 'es' ? spanishDescription : catalanDescription}</p>
                <p className='participadas__p-anchor'><a className='participadas-anchor-website participadas__anchor' href={companyInfo && companyInfo.website}>{companyInfo && companyInfo.website}</a></p>
            </div>
            : null

    )
}
