import React from 'react';
import Carousel from 'react-material-ui-carousel';

export const CarouselHome = ({ t }) => {

    const items = [
        {
            src: '/assets/carousel_1.jpg',
            text: t('carousel1'),
            author: ''
        },
        {
            src: '/assets/carousel_2.jpg',
            text: t('carousel2'),
            author: 'HARVEY MACKAY'
        },
        {
            src: '/assets/carousel_3.jpg',
            text: t('carousel3'),
            author: 'MARK TWAIN'
        },
        {
            src: '/assets/carousel_4.jpg',
            text: t('carousel4'),
            author: 'CHRIS GROSSER'
        },
    ]
    return (
        <Carousel
        timeout={0}
            animation="slide"
            interval={6000}
            className='home__carousel'
            activeIndicatorIconButtonProps={{
                style: {
                    color: 'white',
                    backgroundColor: 'white',
                    border: '3px solid white',
                    height: '1em',
                    width: '1em',
                    margin: '0 2px 0 2px'
                }
            }}
            indicatorIconButtonProps={{
                style: {
                    color: 'transparent',
                    border: '2px solid white',
                    height: '1em',
                    width: '1em',
                    margin: '0 2px 0 2px',
                    position: 'relative',
                    bottom: '3em'
                }
            }}
            navButtonsProps={{
                style: {
                    backgroundColor: 'black'
                }
            }}

        >
            {
                items.map((item, i) => <Photo key={i} item={item} />)
            }
        </Carousel>
    )


    function Photo({ item }) {
        const { text, src, author } = item;

        return (
            <div className="home__carousel-info">
                <div className='home__carousel-content home__fade-in'>
                    <div>
                        <p className='home__carousel-text'>{text}</p>
                        <blockquote className='home__carousel-author'>{author}</blockquote>
                    </div>
                </div>
                <img className='home__scale-in-center home__carousel-img' src={src} alt={src} />
            </div>
        )
    }
}
